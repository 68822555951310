//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ExportEventsHelpStep',
  data() {
    return {};
  },
  props: {
    number: {
      type: Number,
    },
    headline: {
      type: String,
    },
    description: {
      type: String,
    },
    onClickFunc: {
      type: Function,
    },
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    noSeparator: {
      type: Boolean,
    },
  },
  components: {},
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
};
